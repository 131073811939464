import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { environment } from 'src/environments/environment'
import { GetProductTypeDto } from '../dtos/getProductTypeDto'
import { ResponseModel } from '../dtos/responseModel'
import { ConfigService } from './config.service'

@Injectable({
  providedIn: 'root',
})
export class ProductTypeService {
  constructor(
    private readonly _httpClient: HttpClient,
    private readonly _configService: ConfigService,
  ) {}

  getProductTypes(): Observable<ResponseModel<GetProductTypeDto[]>> {
    return this._httpClient.get<ResponseModel<GetProductTypeDto[]>>(`${this._configService.getBaseIdentityUrl()}/product-types`)
  }

  getProductTypeById(id: number): Observable<ResponseModel<GetProductTypeDto>> {
    return this._httpClient.get<ResponseModel<GetProductTypeDto>>(`${this._configService.getBaseIdentityUrl()}/product-types/${id}`)
  }
}
