import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { ResponseModel } from '../dtos/responseModel'
import { RegisterDto } from '../dtos/registerDto'
import { ConfigService } from './config.service'

@Injectable({
  providedIn: 'root',
})
export class RegistrationService {
  constructor(
    private readonly _httpClient: HttpClient,
    private readonly _configService: ConfigService,
  ) {}

  register(registerDto: RegisterDto): Observable<ResponseModel> {
    return this._httpClient.post<ResponseModel>(`${this._configService.getBaseIdentityUrl()}/auth/register`, registerDto)
  }
}
