import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfig } from '../models/appConfig';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  private config!: AppConfig;

  constructor(private readonly _httpClient: HttpClient) {}

  loadConfig(): Promise<void> {
    return this._httpClient
      .get<AppConfig>('/assets/app.config.json')
      .toPromise()
      .then(data => {
        this.config = data;
      });
  }

  getConfig(): AppConfig {
    return this.config;
  }

  getBaseIdentityUrl(): string {
    return this.getConfig().baseIdentityUrl;
  }
}
