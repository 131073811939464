import { Injectable } from '@angular/core'
import { Title } from '@angular/platform-browser'

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  constructor(private readonly _titleService: Title) {}

  getPageTitle(): string {
    return this._titleService.getTitle()
  }

  setPageTitle(title: string): void {
    this._titleService.setTitle(title)
  }

  getSiteSunDarkMode() {
    return localStorage.getItem('mode') ?? 'light'
  }

  setSiteSunDarkMode(mode: string): void {
    localStorage.setItem('mode', mode)
  }

  createLinkAndDownloadFile(data: any, fileName: string, outputExtension: string) {
    var downloadURL = window.URL.createObjectURL(data)
    var link = document.createElement('a')
    link.href = downloadURL
    link.download = `${fileName}.${outputExtension}`
    link.click()
  }
}
