import { Component } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { fadeInAnimation } from 'src/app/animations'
import { APP_CONSTANTS } from 'src/app/constants/app'
import { SharedService } from 'src/app/services/shared.service'
import { RegistrationService } from './services/registration.service'
import { SharedUIService } from './services/shared-ui.service'
import { ProductTypeService } from './services/product-type.service'
import { GetProductTypeDto } from './dtos/getProductTypeDto'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  animations: [fadeInAnimation],
  host: { '[@fadeInAnimation]': '' }
})
export class AppComponent {
  productTypes!: GetProductTypeDto[]

  firmFields = ['Consultancy', 'Transportaion', 'Academician', 'Student', 'Operator', 'Authority', 'System Supplier', 'Other']

  registrationForm!: FormGroup

  isLoading = false
  statusMessage: string = ''

  constructor(
    private readonly _sharedService: SharedService,
    private readonly _registrationService: RegistrationService,
    private readonly _sharedUIService: SharedUIService,
    private readonly _productTypeService: ProductTypeService,
  ) {
    this.registrationForm = new FormGroup({
      username: new FormControl(null, [Validators.required]),
      email: new FormControl(null, [Validators.required, Validators.email]),
      firstName: new FormControl(null, [Validators.required, Validators.minLength(3)]),
      lastName: new FormControl(null, [Validators.required, Validators.minLength(2)]),
      phone: new FormControl(null, [Validators.required]),
      firmName: new FormControl(null, [Validators.required, Validators.minLength(2)]),
      firmField: new FormControl(null, [Validators.required]),
      products: new FormControl([], [Validators.required]),
    })
  }

  ngOnInit(): void {
    this._sharedService.setPageTitle(`${APP_CONSTANTS.APP_NAME} - Register`)

    this._productTypeService.getProductTypes().subscribe(
      (response) => {
        if (response.success) {
          this.productTypes = response.data!.filter((productType) => productType.name.includes('HiSimTrain'))
        } else {
          this._sharedUIService.showErrorSnackBar(response.message)
        }
      },
      (error) => {
        this._sharedUIService.showErrorSnackBar('An error occurred while processing your request. Please try again.')
      },
    )
  }

  isControlInvalid(controlName: string): boolean {
    const control = this.registrationForm.get(controlName)
    return control ? control.invalid : false
  }

  getControlValue(controlName: string): any {
    const control = this.registrationForm.get(controlName)
    return control?.value
  }

  register(): void {
    if (this.registrationForm.invalid) return

    this.isLoading = true

    this._registrationService.register(this.registrationForm.value).subscribe(
      (response) => {
        this.isLoading = false

        if (response.success) {
          this._sharedUIService.showSuccessSnackBar(response.message)
          this.statusMessage = 'Your request has been submitted successfully. Check your mail for further instructions.'
        } else {
          this._sharedUIService.showErrorSnackBar(response.message)
          this.statusMessage = response.message!
        }
      },
      (error) => {
        this.isLoading = false
        this.statusMessage = error.error.message
        this._sharedUIService.showErrorSnackBar(error.error.message)
      },
    )
  }
}
